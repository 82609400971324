<template>
  <div class="Wallets">
    <div class="header">
      <div class="header-title">Wallets</div>
    </div>
    <div class="panel">
      <wallet-statistics />
      <hr />
      <active-wallet-users />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import WalletStatistics from './WalletStatistics';
import ActiveWalletUsers from './ActiveWalletUsers';

const { mapActions, mapMutations } = createNamespacedHelpers('wallets');

export default {
  name: 'Wallets',
  components: {
    WalletStatistics,
    ActiveWalletUsers,
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.clearActiveWallets();
  },
  methods: {
    ...mapActions(['fetchWalletStatistics']),
    ...mapMutations(['clearActiveWallets']),
    init() {
      this.fetchWalletStatistics();
    },
  },
};
</script>

<style lang="scss" scoped></style>
