export const pascalToTitleCase = (value) => {
  if (!value) return '';
  return value
    .split(/(?=[A-Z])/)
    .map((part) => `${part.charAt(0).toUpperCase()}${part.substring(1)}`)
    .join(' ');
};

export const number = (value = 0, digits = 2) => {
  const fixed = value.toFixed(digits);
  return fixed.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
