<template>
  <div class="WalletStatistics">
    <div v-for="(indicator, index) in stats" :key="index" class="Grid-row">
      <div class="Grid-column-4">{{ indicator.label }}:</div>
      <div class="Grid-column-4" v-html="indicator.value"></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { pascalToTitleCase, number } from '@/utils/filters';

const { mapGetters } = createNamespacedHelpers('wallets');

export default {
  name: 'WalletStatistics',
  data() {
    return {
      valuesInKobo: [
        'totalMoneyDeposited',
        'totalMoneyWithdrawn',
        'totalMoneyTransferAmount',
      ],
    };
  },
  computed: {
    ...mapGetters(['walletStatistics']),
    stats() {
      return Object.keys(this.walletStatistics).map((key) => ({
        label: pascalToTitleCase(key),
        value: this.valuesInKobo.includes(key)
          ? `&#8358; ${number(this.walletStatistics[key] / 100)}`
          : this.walletStatistics[key],
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.WalletStatistics {
  font-size: 17px;
  color: #858d9c;
}
</style>
